import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="schroepfen" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Schröpfmassage`}</h1>
      <h3 {...{
        "id": "schropfmassage"
      }}>{`Schröpfmassage`}</h3>
      <p>{`Die Schröpfmassage gehört zu den Verfahren der `}<a parentName="p" {...{
          "href": "/beschwerdebilder/entgiftung"
        }}>{`Entgiftung`}</a>{`, Ausleitung und Reflexzonentherapie.`}</p>
      <p>{`Kleine Glasglocken (Schröpfköpfe) werden z. B. bei `}<a parentName="p" {...{
          "href": "/beschwerdebilder/rueckenbeschwerden"
        }}>{`Rückenbeschwerden`}</a>{` auf verhärtete und verkrampfte Muskelpartien gesetzt und mit einer Saugpumpe ein Vakuum erzeugt.`}</p>
      <p>{`Reaktiv kommt es zu einer verstärkten Durchblutung und besserer Sauerstoffversorgung des Gewebes. Schlackenstoffe aus dem Bindegewebe kommen in Lösung und werden abtransportiert. Sanfte bis kräftige Bewegungen der Saugnäpfe auf der Haut verbessern die Mikrozirkulation im Gewebe und geben reflektorische Reize über die Hautnerven an die entsprechenden Rückenmarksegmente. Neben einer Stimulation innerer Organe (Leber, Niere, Herz) kann so auch das vegetative Nervensystem bei `}<a parentName="p" {...{
          "href": "/beschwerdebilder/erschoepfung_stress"
        }}>{`Erschöpfung / Stress`}</a>{` stabilisiert werden.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      